<template>
  <div id="article-container">
    <Head />
    <div class="article_title">{{ data.title }}</div>
    <div class="content" v-html="data.content"></div>
  </div>
</template>

<script>
import Head from "@/components/head";
export default {
  data() {
    return {
      type: this.$route.query.type,
      id: this.$route.query.id,
      data: {},
    };
  },
  components: {
    Head,
  },
  computed: {},
  methods: {
    getArticle() {
      this.$api.articleDetail({ type: this.type, id: this.id }).then((res) => {
        if (res.data.status) {
          this.data = res.data.data;
        }
      });
    },
  },
  created() {},
  mounted() {
    this.getArticle();
  },
};
</script>

<style lang="scss">
#article-container {
  width: 100%;
  .article_title {
    margin-top: 180px;
    width: 100%;
  }
  .content {
    margin-top: 20px;
    width: 100%;
    img {
      width: 100% !important;
      display: block !important;
    }
  }
}
</style>